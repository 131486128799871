<template>
  <page-container title="参数下发日志" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备注册代码" prop="devicenum">
              <a-input v-model.trim="queryParams.devicenum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="返回结果" prop="returncode">
              <a-select v-model="queryParams.returncode">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option value="0">处理中</a-select-option>
                <a-select-option value="1">成功</a-select-option>
                <a-select-option value="2">失败</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备组名称" prop="devicegroupname">
              <a-input v-model.trim="queryParams.devicegroupname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="操作时间" prop="starttime">
              <a-range-picker v-model="queryDataRange">
              </a-range-picker>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">参数下发列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="deleteBatch()">删除</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :row-selection="{onChange: rowSelectChange}" :columns="tableColumns" :data-source="tableData" row-key="devparamrecordid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="opstarttime" slot-scope="text">
            <span :title="text ? moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">{{text ? moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </span>
          <span slot="opendtime" slot-scope="text">
            <span :title="text ? moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">{{text ? moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </span>
          <span slot="returncode" slot-scope="text">
            <span class="text" :title="returncodeMap[text]||''">{{returncodeMap[text]||''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}/${counts}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </page-container>
</template>
<script>
import moment from 'moment'
import pagination from '@/mixins/pagination'
import { getDevparamrecordListByCondition, deleteDevparamrecord } from 'A/ai.js'
export default {
  mixins: [pagination],
  data() {
    return {
      moment,
      showAdvanced: false,
      breadcrumb: [
        {
          name: 'AI+物联网监测',
          path: ''
        },
        {
          name: '监测设备管理',
          path: ''
        },
        {
          name: '参数下发日志',
          path: ''
        },
      ],
      queryParams: {
        devicenum: '',
        returncode: '',
        devicegroupname: '',
        starttime: '',
        endtime: '',
      },
      queryDataRange: [],
      tableColumns: [
        {
          title: '设备注册代码',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
          width: 180,
        },
        {
          title: '下发参数',
          dataIndex: 'opdata',
          key: 'opdata',
          ellipsis: true,
        },
        {
          title: '操作开始时间',
          dataIndex: 'opstarttime',
          key: 'opstarttime',
          ellipsis: true,
          width: 150,
          scopedSlots: { customRender: 'opstarttime' }
        },
        {
          title: '操作结束时间',
          dataIndex: 'opendtime',
          key: 'opendtime',
          ellipsis: true,
          width: 150,
          scopedSlots: { customRender: 'opendtime' }
        },
        {
          title: '返回结果',
          dataIndex: 'returncode',
          key: 'returncode',
          ellipsis: true,
          width: 80,
          scopedSlots: { customRender: 'returncode' }
        },
        {
          title: '操作账号',
          dataIndex: 'useraccount',
          key: 'useraccount',
          width: 80,
          ellipsis: true,
        },
        {
          title: '设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
          width: 150
        },
        {
          title: '操作',
          key: 'operation',
          width: 70,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      batchrowids: '',
      counts:'',
    }
  },
  computed: {
    returncodeMap() {
      let result = {
        '0': '处理中',
        '1': '成功',
        '2': '失败',
      };
      return result;
    },
  },
  watch: {
    queryDataRange(val) {
      if(val && val.length) {
        this.queryParams.starttime = moment(val[0], 'YYYY-MM-DD').format('YYYYMMDD');
        this.queryParams.endtime = moment(val[1], 'YYYY-MM-DD').format('YYYYMMDD');
      }else {
        this.queryParams.starttime = '';
      }
    }
  },
  created() {
    this.getTableData();
  },
  methods: {
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
      this.queryDataRange = [];
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      if(params.devicegroupname==''&&params.devicenum==''&&params.returncode==''&&params.starttime==''&&params.endtime==''){
        getDevparamrecordListByCondition(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.counts = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
      getDevparamrecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.devparamrecordid);
        },
        onCancel() {},
      });
    },
    delete(devparamrecordid) {
      this.showLoading();
      if(devparamrecordid) {
        let params = {
          devparamrecordid
        };
        deleteDevparamrecord(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      this.batchrowids = selectedRowKeys.join('|');
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm({
        // title: '',
        content: '确定要删除选中数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(this.batchrowids);
        },
        onCancel() {},
      });
    },
  }
}
</script>